<template>
	<div class="home">
		<h1 class="text-2xl font-semibold text-gray-900">Upload Test</h1>
	</div>
	<div>
		<!-- <form @submit.prevent="form.post(route('jobs.services.upload', jobService.id), { preserveScroll: true })"> -->
		<form @submit.prevent="submitForm()">
			<div>
				<media-library-collection
					name="cover_image"
					:initial-value="form.gallery"
					upload-domain="http://api.local.sc.care:8000"
					route-prefix="cms/media"
					:validation-rules="{
						accept: ['image/jpeg', 'image/png'],
						maxSizeInKB: 15 * 1024,
					}"
					vapor-signed-storage-url="http://api.local.sc.care:8000/vapor/signed-storage-url"
					vapor
					:with-credentials="true"
					@change="thisMedia => (form.gallery = thisMedia)"
				/>
				<!-- <media-library-attachment
					name="cover_image"
					:initial-value="form.media"
					upload-domain="http://api.local.sc.care:8000"
					route-prefix="cms/media"
					:validation-rules="{
						accept: ['image/jpeg', 'image/png'],
						maxSizeInKB: 15 * 1024,
					}"
					vapor-signed-storage-url="http://api.local.sc.care:8000/vapor/signed-storage-url"
					vapor
					:with-credentials="true"
					@change="thisMedia => (form.media = thisMedia)"
				/> -->
			</div>
			<button
				type="submit"
				:disabled="formProcessing"
				class="inline-flex justify-center px-4 py-2 mt-3 ml-3 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
			>
				Save
			</button>
		</form>
	</div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted, ref, reactive } from 'vue'
import { useToast } from 'vue-toastification'
// import { MediaLibraryAttachment } from '@spatie/media-library-pro-vue3-attachment'
import { MediaLibraryCollection } from '@spatie/media-library-pro-vue3-collection'

import axios from 'axios'

export default {
	name: 'Index',
	components: { MediaLibraryCollection },
	setup() {
		const store = useStore()
		const router = useRouter()
		const toast = useToast()
		const form = reactive({
			gallery: null,
		})

		return {
			form,
			formProcessing: ref(false),
			submitForm: async () => {
				console.log('Submitting form!')
				console.log('Form Data:', form)
				const slug = 'cheryl-brown'
				try {
					const res = await axios.post('providers/' + slug + '/gallery/upload', form)
					if (res.status != 200) throw res
					console.log('200 returned. Res:', res)
				} catch (err) {
					console.log('Failed to load user data! Error: ', err.response.status)
					throw err
				}
			},
		}
	},
}
</script>
